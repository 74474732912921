import React from "react";
import PropTypes from "prop-types";

const Bottom = ({ data }) => (
  <section className="bottom">
    <svg
      className="bottom__logo--mobile"
      viewBox="0 0 1396 1898"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
    >
      <path
        d="M777.833 1757.036v15.092h-78.012v44.496h69.975v14.896H699.82v47.633h78.012v15.092h-93.692v-137.209h93.692zM852.904 1876.22l45.28-119.175h17.245l-52.92 137.208h-19.41l-52.724-137.208h17.25l45.28 119.175zM1021.858 1757.036v15.092h-78.012v44.496h69.975v14.896h-69.975v47.633h78.012v15.092h-93.691v-137.209h93.691zM1062.038 1757.036l79.97 115.059v-115.059h15.684v137.209h-19.209l-80.758-116.434-.392 116.434h-15.683v-137.209h20.388zM1282.35 1757.036v15.092h-45.475v122.117h-15.679v-122.117h-45.48v-15.092h106.634zM1344.092 1882.074c22.15 0 35.675-10.975 35.675-27.442 0-13.916-12.35-22.341-39.792-25.283-30.187-3.138-45.475-15.88-45.475-38.813 0-21.17 19.796-36.262 47.042-36.262 25.092 0 44.888 15.88 50.18 39.987l-15.288 5.292c-3.921-18.23-17.446-30.187-34.892-30.187-18.23 0-30.967 8.629-30.967 20.975 0 13.72 9.992 21.166 33.321 23.525 34.496 3.525 51.942 16.462 51.942 40.766 0 25.48-20.775 42.538-51.746 42.538-31.167 0-53.708-19.8-58.804-50.771l15.487-4.508c3.721 24.895 20.384 40.183 43.317 40.183"
        fill="#fff"
        fillRule="nonzero"
      />
      <path
        fill="#fff"
        d="M651.428 1816.649L.003 1816.49l-.004 14.629 651.426.158zM498.97 938.194H.003v14.625H498.97zM1395.838 938.194H630.483v14.625h765.355z"
      />
      <path
        d="M572.954 878.59h-18.421l-56.254 137.196h17.054l16.462-40.571 6.08-15.096 25.87-63.692 25.671 63.692 6.075 15.096 16.467 40.57H629.2L572.954 878.59zM106.635.072v15.092H61.16v122.121H45.48V15.165H.007V.071h106.63zM174.842 67.702L217.767.077h18.23l-53.318 83.5v53.708H167V83.577L114.08.077h17.837l42.925 67.625zM332.233 39.277c0-14.504-8.82-24.113-26.066-24.113h-41.755v48.417h41.755c17.245 0 26.066-9.608 26.066-24.304M306.754.072c26.458 0 41.163 16.859 41.163 39.205 0 22.737-14.705 39.2-41.163 39.2h-42.342v58.804h-15.679V.072h58.021zM458.658.072v15.092h-78.013v44.5h69.975V74.56h-69.975v47.63h78.013v15.095h-93.696V.072h93.696z"
        fill="#fff"
        fillRule="nonzero"
      />
      <path
        fill="#fff"
        d="M1395.838 59.685H477.44V74.31h918.397zM1395.838 500.619H.003v14.625h1395.835zM1395.838 280.998H.003v14.625h1395.835zM1395.838 720.231H.003v14.63h1395.835zM1395.838 1379.165H.003v14.625h1395.835zM1395.838 1159.507H.003v14.625h1395.835zM1395.838 1598.778H.003v14.63h1395.835zM593.104 938.194H534.37v14.625h58.734z"
      />
    </svg>

    <svg
      viewBox="0 0 5026 1911"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      className="bottom__logo--desktop"
    >
      <path
        d="M2916.071 1432.511v51.517h-266.3v151.888h238.867v50.85h-238.867v162.592h266.3v51.52h-319.829v-468.367h319.83zM3172.326 1839.303l154.562-406.812h58.88l-180.659 468.367h-66.237L2958.88 1432.49h58.883l154.563 406.812zM3749.06 1432.511v51.517h-266.3v151.888h238.866v50.85H3482.76v162.592h266.3v51.52H3429.23v-468.367h319.83zM3886.214 1432.511l272.992 392.763v-392.763h53.525v468.367h-65.571l-275.667-397.446-1.337 397.446h-53.53v-468.367h69.588zM4638.248 1432.511v51.517h-155.23v416.85h-53.529v-416.85H4274.26v-51.517h363.988zM4848.998 1859.4c75.608 0 121.775-37.472 121.775-93.672 0-47.508-42.154-76.279-135.83-86.316-103.04-10.709-155.228-54.196-155.228-132.48 0-72.266 67.579-123.783 160.583-123.783 85.646 0 153.225 54.196 171.288 136.496l-52.188 18.067c-13.383-62.23-59.55-103.046-119.1-103.046-62.225 0-105.717 29.441-105.717 71.596 0 46.837 34.121 72.258 113.746 80.291 117.763 12.042 177.313 56.2 177.313 139.175 0 86.98-70.925 145.192-176.642 145.192-106.388 0-183.333-67.58-200.733-173.3l52.862-15.387c12.713 84.979 69.583 137.166 147.871 137.166"
        fill="#fff"
        fillRule="nonzero"
      />
      <path
        fill="#fff"
        d="M2484.586 1636.003L.009 1635.45l-.011 49.921 2484.577.554zM1835.166 912.894H.011v49.921h1835.155zM5024.652 912.894h-2740.56v49.921h2740.56zM2156.488 912.894H1956v49.921h200.488z"
      />
      <path
        d="M2087.7 709.44h-62.88l-192.024 468.313h58.208l56.196-138.48 20.754-51.524 88.304-217.43 87.634 217.43 20.737 51.525 56.213 138.479h58.862L2087.7 709.44zM368.903.052v51.517h-155.23v416.85h-53.529V51.569H4.915V.052h363.988zM601.732 230.894L748.266.056h62.225L628.495 285.09v183.333h-53.53V285.09L394.311.056H455.2l146.533 230.838zM1139 133.894c0-49.513-30.11-82.3-88.988-82.3H907.49v165.27h142.52c58.88 0 88.988-32.787 88.988-82.97M1052.02.077c90.33 0 140.508 57.538 140.508 133.817 0 77.616-50.179 133.82-140.508 133.82H907.49v200.73h-53.524V.077h198.054zM1570.55.052v51.517h-266.3v151.887h238.866v50.85H1304.25v162.592h266.3v51.521H1250.72V.052h319.83z"
        fill="#fff"
        fillRule="nonzero"
      />
      <path fill="#fff" d="M5024.648 203.552H1634.675v49.921h3389.973z" />
    </svg>
    <div className="bottom__links">
      <nav className="bottom__nav">
        <ul>
          <li>
            <span>Type-A-Events</span>
            <span>&copy; {new Date().getFullYear()}</span>
          </li>
          {data.email && (
            <li>
              <span>Questions?</span>
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </li>
          )}
          {data.email && (
            <li>
              <span>Press Inquiries?</span>
              <a href={`mailto:${data.pressemail}`}>{data.pressemail}</a>
            </li>
          )}
          {data.instagram && data.facebook && (
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${data.instagram}`}
              >
                Instagram
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${data.facebook}`}
              >
                Facebook
              </a>
            </li>
          )}
        </ul>
      </nav>
    </div>
  </section>
);

Bottom.propTypes = {
  data: PropTypes.object
};

export default Bottom;
